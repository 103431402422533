* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
}

:root {
	--default-text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

	--color-border: black;
	--color-background: #F9F9F9;
	--color-text-cell-title: #999;
	--color-text-cell-value: #1e1e1e;
	--color-text: #343434;
}

.background {
	background-color: var(--color-background);
	background-position: center;
	background-size: cover;
}

body {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	font-family: Arial, sans-serif;
}

.container {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: 20px;
	position: absolute;
	top: 100px;
	margin-bottom: 20px;
	width: 90%;
	padding-bottom: 100px;
}

.navbar {
	height: 100px;
	width: 100%;
	opacity: 1;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	bottom: 50px;
}

.container.inspect-mode tilt-card {
	pointer-events: none;
}

.container.inspect-mode tilt-card.focused {
	pointer-events: all;
}

tilt-card {
	transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

tilt-card.focused {
	position: relative;
	z-index: 100;
	pointer-events: all;
	opacity: 1 !important;
	transform: translate(var(--center-x), var(--center-y));
}

.fade-out {
	animation: fadeOut 0.5s forwards;
}

tilt-card {
	transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.fade-in {
	animation: fadeIn 0.5s forwards;
}

@keyframes fadeOut {
	0% {
		opacity: 1;
		scale: 1;
	}

	100% {
		opacity: 0;
		scale: 0.9;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
		scale: 0.9;
	}

	100% {
		opacity: 1;
		scale: 1;
	}
}

.info-text {
	position: absolute;
	font-family: 'Courier New', monospace;
	font-size: 20px;
	color: #FFF;
	opacity: 1;
	white-space: nowrap;
	overflow: hidden;
	z-index: 2;
	transition: opacity 0.5s ease-in-out;
}

.info-text.left {
	right: 100%;
	margin-right: 20px;
	text-align: right;
	transform-origin: right;
}

.info-text.right {
	left: 100%;
	margin-left: 20px;
	text-align: left;
}

.info-text.top {
	bottom: 100%;
	margin-bottom: 5px;
	text-align: center;
	width: 100%;
	transform-origin: center;
}

.info-text.bottom {
	top: 100%;
	margin-top: 5px;
	text-align: center;
	width: 100%;
}

.outerWrap {
	background-color: #444;
}

.outerWrap {
	border: 1px solid #222;
	margin: 30px auto;
	width: 530px;
}

.inspect {
	position: fixed;
	top: 100px;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	display: grid;
	grid-template-columns: 1fr auto 1fr;
}

.inspect .left-column,
.inspect .right-column,
.inspect .middle-column {
	padding: 3%;
	box-sizing: border-box;
}

.inspect .middle-column {
	padding-left: 0;
	padding-right: 0;
}

.column-inner {
	background: rgba(255, 255, 255, 0.11);
	border: 1px solid rgba(255, 255, 255, 0.24);
	backdrop-filter: blur(9.4px);
	/* Note: backdrop-filter has minimal browser support */
	border-radius: 20px;

	/*center all content horizontally*/
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.left-column .column-inner,
.right-column .column-inner {
	padding-top: 1.5em;
}

.inspect {
	padding-top: 1.5em;
	padding-left: 1.5em;
	padding-right: 1.5em;
}

.inspect .middle-column {
	flex-grow: 0;
	flex-shrink: 0;
	width: auto;
}

.card-placeholder {
	width: 350px;
	height: 500px;
}


.player-name {
	padding: 10px;
	text-shadow: var(--default-text-shadow);

	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 700;
	font-size: 40px;
	line-height: 3vw;

	color: #FFFFFF;
}

.team-title {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 25px;
	line-height: 30px;
	color: #FFFFFF;
	padding: 10px;
	text-shadow: var(--default-text-shadow);
}

.team-subtitle {
	text-shadow: var(--default-text-shadow);
	padding-top: 10px;
	padding-bottom: 10px;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 700;
	font-size: 15px;
	line-height: 25px;
	text-align: center;

	color: #FFFFFF;
}


.team-logo {
	width: 100px;
	height: 100px;
}

.left-column,
.right-column {
	opacity: 0.5;
}

.hidden {
	opacity: 0;
}


.inspect-mode .left-column,
.inspect-mode .right-column {
	animation: blur-in 0.5s;
}

@keyframes blur-in {
	0% {
		opacity: 0.2;
		filter: blur(50px);
	}

	100% {
		opacity: 0.5;
		filter: blur(0px);
	}
}

header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 64px;
	background: white;
}

header,
header * {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 30px;
}

.logo {
	width: 130px;
	aspect-ratio: auto 130 / 25;
	height: 25px;
	width: auto;
}

nav {
	display: flex;
	flex: 1;
	justify-content: center;
}

.nav-links {
	display: flex;
	list-style: none;
	cursor: pointer;
}

.nav-links li a {
	text-decoration: none;

	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	padding: 8px 16px;
	color: #191c20;
	cursor: pointer;
}

.nav-links li a:hover {
	color: #666;
	cursor: pointer;
}

.contact-us-button {
	text-align: right;
}

.contact-us-button button {
	background: white;
	border: 1px solid black;
	border-radius: 16px;
	color: black;
	padding: 10px 20px;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	margin: 4px 2px;
	transition-duration: 0.2s;
	text-align: center;
	cursor: pointer;
}

.left-side-table {
	display: table;
	flex-wrap: wrap;
	margin: 20px;
	width: 100%;
	text-align: center;
}

.player-stats-table {
	display: table;
	flex-wrap: wrap;
	margin: 20px;
	width: 100%;
	text-align: center;
}

player-stat-table {
	padding: 10px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 10px;
}

player-stat-table .cell {
	padding: 5px;
	border: 1px solid black;
	background: rgba(255, 255, 255, 0.11);
	border: 1px solid rgba(255, 255, 255, 0.5);
	backdrop-filter: blur(9.4px);
	border-radius: 20px;
	box-sizing: border-box;
}

player-stat-table .cell .item-title {
	font-size: 24px;
	font-weight: 600;
	text-align: center;
	color: white;
	text-shadow: var(--default-text-shadow);

}

player-stat-table .cell .item-value {
	font-size: 20px;
	font-weight: 500;
	text-align: center;
	color: white;
}

card-stat-table {
	padding: 10px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 10px;
}

card-stat-table .cell {
	padding: 5px;
	border: 1px solid black;
	background: rgba(255, 255, 255, 0.11);
	border: 1px solid rgba(255, 255, 255, 0.5);
	backdrop-filter: blur(9.4px);
	border-radius: 20px;
	box-sizing: border-box;
}

card-stat-table .cell .trait-title {
	font-size: 15px;
	font-weight: 600;
	text-align: center;
	color: white;
	text-shadow: var(--default-text-shadow);
}

card-stat-table .cell .trait-value {
	font-size: 15px;
	font-weight: 500;
	text-align: center;
	color: white;
}


.right-side-chart {
	max-width: 100%;
	;
}

.white-horizontal-line {
	width: 100%;
	margin-right: 10px;
	margin-left: 10px;
	box-sizing: border-box;
}

.card-value,
.right-side-chart-title {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 700;
	font-size: 40px;
	line-height: 3vw;

	color: #FFFFFF;
	margin-bottom: 0.5em;
}

.left-side-chart-title {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 700;
	font-size: 40px;
	line-height: 3vw;

	color: #FFFFFF;
}

.grid-card-container {
	padding: 16px;
	background: white;
	border-radius: 10px;
}

.grid-card-share-icon {
	position: absolute;
	top: 10px;
	right: 8px;
	background-image: url('./assets/share_icon.png');
	background-repeat: no-repeat;
	background-size: contain;
	width: 33px;
	height: 33px;
}

.grid-card-container-inner {
	position: relative;
	padding-left: 51px;
	padding-right: 51px;
	padding-top: 30px;
	padding-bottom: 30px;
	background: linear-gradient(to right, #EFF6FF, #F3F3F3);
}

.grid-card-title {
	margin-top: 14px;
	color: var(--grey-400343434, #343434);
	font-size: 14px;
	font-family: Aktiv Grotesk Corp;
	font-weight: 500;
	line-height: 19.515px;
}

.grid-card-price {
	margin-top: 8px;
	color: var(--grey-400343434, #343434);
	font-size: 24px;
	font-family: Aktiv Grotesk Corp;
	line-height: 19.515px;
}

.grid-card-button-container {
	margin-top: 32px;
	display: flex;
	gap: 8px;
}

.grid-card-button-redeem,
.grid-card-button-sell {
	width: 50%;
	height: 41px;
	line-height: 41px;

	display: flex;
	padding: 8px 0px;
	justify-content: center;
	align-items: center;
	flex: 1 0 0;
	align-self: stretch;

	cursor: pointer;
}

.grid-card-sell-icon {
	width: 26px;
	height: 26px;
	transform: rotate(-1.45deg);
	background-image: url('./assets/opensea_icon.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.grid-card-button-sell {
	color: var(--white-white, #FFF);
	text-align: center;
	font-size: 14px;
	font-family: Aktiv Grotesk Corp;
	font-weight: 700;
	border-radius: 9.76px;
	background: #2081E2;
}

.grid-card-button-redeem {
	border-radius: 9.76px;
	border: 1px solid var(--grey-300999999, #999);
	background: #FFF;
	color: var(--grey-400343434, #343434);
	text-align: center;
	font-size: 14px;
	font-family: Aktiv Grotesk Corp;
	font-weight: 700;
}