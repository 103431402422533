* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
}

:root {
  --default-text-shadow: 0px 2px 4px #00000040;
  --color-border: black;
  --color-background: #f9f9f9;
  --color-text-cell-title: #999;
  --color-text-cell-value: #1e1e1e;
  --color-text: #343434;
}

.background {
  background-color: var(--color-background);
  background-position: center;
  background-size: cover;
}

body {
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: Arial, sans-serif;
  display: flex;
}

.container {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 90%;
  margin-bottom: 20px;
  padding-bottom: 100px;
  display: flex;
  position: absolute;
  top: 100px;
}

.navbar {
  opacity: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100px;
  bottom: 50px;
}

.container.inspect-mode tilt-card {
  pointer-events: none;
}

.container.inspect-mode tilt-card.focused {
  pointer-events: all;
}

tilt-card.focused {
  z-index: 100;
  pointer-events: all;
  transform: translate(var(--center-x), var(--center-y));
  position: relative;
  opacity: 1 !important;
}

.fade-out {
  animation: .5s forwards fadeOut;
}

tilt-card {
  transition: opacity .5s ease-in-out, transform .5s ease-in-out;
}

.fade-in {
  animation: .5s forwards fadeIn;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    scale: 1;
  }

  100% {
    opacity: 0;
    scale: .9;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    scale: .9;
  }

  100% {
    opacity: 1;
    scale: 1;
  }
}

.info-text {
  color: #fff;
  opacity: 1;
  white-space: nowrap;
  z-index: 2;
  font-family: Courier New, monospace;
  font-size: 20px;
  transition: opacity .5s ease-in-out;
  position: absolute;
  overflow: hidden;
}

.info-text.left {
  text-align: right;
  transform-origin: 100%;
  margin-right: 20px;
  right: 100%;
}

.info-text.right {
  text-align: left;
  margin-left: 20px;
  left: 100%;
}

.info-text.top {
  text-align: center;
  transform-origin: center;
  width: 100%;
  margin-bottom: 5px;
  bottom: 100%;
}

.info-text.bottom {
  text-align: center;
  width: 100%;
  margin-top: 5px;
  top: 100%;
}

.outerWrap {
  background-color: #444;
  border: 1px solid #222;
  width: 530px;
  margin: 30px auto;
}

.inspect {
  pointer-events: none;
  grid-template-columns: 1fr auto 1fr;
  width: 100%;
  height: 100%;
  display: grid;
  position: fixed;
  top: 100px;
  left: 0;
}

.inspect .left-column, .inspect .right-column, .inspect .middle-column {
  box-sizing: border-box;
  padding: 3%;
}

.inspect .middle-column {
  padding-left: 0;
  padding-right: 0;
}

.column-inner {
  -webkit-backdrop-filter: blur(9.4px);
  backdrop-filter: blur(9.4px);
  background: #ffffff1c;
  border: 1px solid #ffffff3d;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.left-column .column-inner, .right-column .column-inner {
  padding-top: 1.5em;
}

.inspect {
  padding-top: 1.5em;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.inspect .middle-column {
  flex-grow: 0;
  flex-shrink: 0;
  width: auto;
}

.card-placeholder {
  width: 350px;
  height: 500px;
}

.player-name {
  text-shadow: var(--default-text-shadow);
  color: #fff;
  padding: 10px;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 3vw;
}

.team-title {
  color: #fff;
  text-shadow: var(--default-text-shadow);
  padding: 10px;
  font-family: Montserrat;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.team-subtitle {
  text-shadow: var(--default-text-shadow);
  text-align: center;
  color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
}

.team-logo {
  width: 100px;
  height: 100px;
}

.left-column, .right-column {
  opacity: .5;
}

.hidden {
  opacity: 0;
}

.inspect-mode .left-column, .inspect-mode .right-column {
  animation: .5s blur-in;
}

@keyframes blur-in {
  0% {
    opacity: .2;
    filter: blur(50px);
  }

  100% {
    opacity: .5;
    filter: blur();
  }
}

header {
  background: #fff;
  width: 100%;
  height: 64px;
  position: absolute;
  top: 0;
  left: 0;
}

header, header * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

header {
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  display: flex;
}

.logo {
  aspect-ratio: auto 130 / 25;
  width: auto;
  height: 25px;
}

nav {
  flex: 1;
  justify-content: center;
  display: flex;
}

.nav-links {
  cursor: pointer;
  list-style: none;
  display: flex;
}

.nav-links li a {
  color: #191c20;
  cursor: pointer;
  padding: 8px 16px;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none;
}

.nav-links li a:hover {
  color: #666;
  cursor: pointer;
}

.contact-us-button {
  text-align: right;
}

.contact-us-button button {
  color: #000;
  text-align: center;
  cursor: pointer;
  background: #fff;
  border: 1px solid #000;
  border-radius: 16px;
  margin: 4px 2px;
  padding: 10px 20px;
  font-size: 16px;
  text-decoration: none;
  transition-duration: .2s;
  display: inline-block;
}

.left-side-table, .player-stats-table {
  text-align: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 20px;
  display: table;
}

player-stat-table {
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  padding: 10px;
  display: grid;
}

player-stat-table .cell {
  -webkit-backdrop-filter: blur(9.4px);
  backdrop-filter: blur(9.4px);
  box-sizing: border-box;
  background: #ffffff1c;
  border: 1px solid #ffffff80;
  border-radius: 20px;
  padding: 5px;
}

player-stat-table .cell .item-title {
  text-align: center;
  color: #fff;
  text-shadow: var(--default-text-shadow);
  font-size: 24px;
  font-weight: 600;
}

player-stat-table .cell .item-value {
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

card-stat-table {
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 10px;
  display: grid;
}

card-stat-table .cell {
  -webkit-backdrop-filter: blur(9.4px);
  backdrop-filter: blur(9.4px);
  box-sizing: border-box;
  background: #ffffff1c;
  border: 1px solid #ffffff80;
  border-radius: 20px;
  padding: 5px;
}

card-stat-table .cell .trait-title {
  text-align: center;
  color: #fff;
  text-shadow: var(--default-text-shadow);
  font-size: 15px;
  font-weight: 600;
}

card-stat-table .cell .trait-value {
  text-align: center;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}

.right-side-chart {
  max-width: 100%;
}

.white-horizontal-line {
  box-sizing: border-box;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.card-value, .right-side-chart-title {
  color: #fff;
  margin-bottom: .5em;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 3vw;
}

.left-side-chart-title {
  color: #fff;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 3vw;
}

.grid-card-container {
  background: #fff;
  border-radius: 10px;
  padding: 16px;
}

.grid-card-share-icon {
  background-image: url("share_icon.7205b07b.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 33px;
  height: 33px;
  position: absolute;
  top: 10px;
  right: 8px;
}

.grid-card-container-inner {
  background: linear-gradient(to right, #eff6ff, #f3f3f3);
  padding: 30px 51px;
  position: relative;
}

.grid-card-title {
  color: var(--grey-400343434, #343434);
  margin-top: 14px;
  font-family: Aktiv Grotesk Corp;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.515px;
}

.grid-card-price {
  color: var(--grey-400343434, #343434);
  margin-top: 8px;
  font-family: Aktiv Grotesk Corp;
  font-size: 24px;
  line-height: 19.515px;
}

.grid-card-button-container {
  gap: 8px;
  margin-top: 32px;
  display: flex;
}

.grid-card-button-redeem, .grid-card-button-sell {
  cursor: pointer;
  flex: 1 0 0;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  width: 50%;
  height: 41px;
  padding: 8px 0;
  line-height: 41px;
  display: flex;
}

.grid-card-sell-icon {
  background-image: url("opensea_icon.58c07b69.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 26px;
  height: 26px;
  transform: rotate(-1.45deg);
}

.grid-card-button-sell {
  color: var(--white-white, #fff);
  text-align: center;
  background: #2081e2;
  border-radius: 9.76px;
  font-family: Aktiv Grotesk Corp;
  font-size: 14px;
  font-weight: 700;
}

.grid-card-button-redeem {
  border: 1px solid var(--grey-300999999, #999);
  color: var(--grey-400343434, #343434);
  text-align: center;
  background: #fff;
  border-radius: 9.76px;
  font-family: Aktiv Grotesk Corp;
  font-size: 14px;
  font-weight: 700;
}

/*# sourceMappingURL=index.d58bcb18.css.map */
